<template>
    <div class="flex flex-wrap justify-center p-2 pb-12 text-gray-700">
        <div class="flex items-center w-full gap-3 mx-auto lg:w-11/12">
            <router-link :to="{name : 'AffiliateOrders' , query : {page : 1 , perPage : 10}}" class="mt-1">
                <span class="material-icons-round">west</span> 
            </router-link>
            <h1 class="text-2xl">Create Order</h1>
        </div>
        <form @submit.prevent="createOrder" class="w-full lg:w-11/12">
            <h1 class="mt-8 text-[1.35rem] font-semibold">{{ $t('titles.basic_info') }}</h1>
            <div class="grid gap-4 mt-2 md:grid-cols-2">
                <div>
                    <label class="block mb-1 text-label" for="name">{{ $t('labels.name') }}</label>
                    <InputText
                        v-model="order.name"
                        required
                        name="name"
                        type="text"
                        placeholder="Jhon Doe"
                        class="w-full"
                     />      
                     <p v-if="errors.name" class="text-sm text-danger">{{ errors.name[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1 text-danger" for="email">{{ $t('labels.email') }}</label>
                    <InputText
                        v-model="order.email"
                        name="email"
                        type="text"
                        required
                        placeholder="(Optional)"
                        class="w-full"
                     />        
                     <p v-if="errors.email" class="text-sm text-danger">{{ errors.email[0] }}</p>                  
                </div>
                <div>
                    <label class="block mb-1" for="phone_no">{{ $t('labels.phone_no') }}</label>
                    <InputText
                        v-model="order.phone_no"
                        name="phone_no"
                        type="text"
                        placeholder="1234567890"
                        class="w-full"
                     />
                     <p v-if="errors.phone_no" class="text-sm text-danger">{{ errors.phone_no[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1" for="fb_link">{{ $t('labels.fb_link') }}</label>
                    <InputText
                        v-model="order.fb_link"
                        name="fb_link"
                        type="text"
                        placeholder="(Optional)"
                        class="w-full"
                     />    
                     <p v-if="errors.fb_link" class="text-sm text-danger">{{ errors.fb_link[0] }}</p>                      
                </div>
                <div class="col-start-1">
                    <label class="block mb-1 text-label" for="postal_code">{{ $t('labels.postal_code') }}</label>
                    <InputText
                        v-model="order.postal_code"
                        name="postal_code"
                        type="text"
                        @change="checkPostalCode"
                        placeholder="1234567"
                        class="w-full"
                     />
                     <p v-if="errors.postal_code" class="text-sm text-danger">{{ errors.postal_code[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1 text-label" for="state_id">{{ $t('labels.state') }}</label>
                    <Dropdown
                        v-model="order.state_id"
                        :options="states"
                        option-label="name"
                        option-value="id"
                        filter
                        optionLabel="name"
                        placeholder="Select a state"
                        class="w-full" />        
                        <p v-if="errors.state_id" class="text-sm text-danger">{{ errors.state_id[0] }}</p>               
                </div>
                <div>
                    <label for="address" class="block mb-1 text-label">{{ $t('labels.address') }}</label>
                    <Textarea
                        v-model="order.address"
                        placeholder="enter full address"
                        name="address"
                        rows="1"
                        cols="30"
                        class="w-full"
                    />
                    <p v-if="errors.address" class="text-sm text-danger">{{ errors.address[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1" for="apartment_name">{{ $t('labels.apartment') }}</label>
                    <InputText
                        v-model="order.apartment_name"
                        name="apartment_name"
                        type="text"
                        placeholder="(Optional)"
                        class="w-full"
                        />
                        <p v-if="errors.apartment_name" class="text-sm text-danger">{{ errors.apartment_name[0] }}</p>                      
                </div>
            </div>

            <h1 class="mt-8 text-[1.35rem] font-semibold">{{ $t('titles.deli_info') }}</h1>
            <div class="grid gap-4 mt-2 md:grid-cols-2">
                <div>
                    <label class="block mb-1" for="delivery_accept_time_id">{{ $t('labels.accept_time') }}</label>
                    <Dropdown
                        v-model="order.delivery_accept_time_id"
                        :options="acceptTimes"
                        option-label="name"
                        option-value="id"
                        optionLabel="name"
                        filter
                        placeholder="(Optional)"
                        class="w-full" />      
                        <p v-if="errors.delivery_accept_time_id" class="text-sm text-danger">{{ errors.delivery_accept_time_id[0] }}</p>                 
                </div>
                <div>
                    <label class="block mb-1" for="delivery_accept_date">{{ $t('labels.accept_date') }}</label>
                    <Calendar
                        v-model="order.delivery_accept_date"
                        showButtonBar
                        placeholder="(Optional)"
                        class="w-full"
                    />   
                    <p v-if="errors.delivery_accept_date" class="text-sm text-danger">{{ errors.delivery_accept_date[0] }}</p>          
                </div>
            </div>

            <div class="mt-8">
                <p class="mb-1 text-label">{{ $t('titles.activate_on') }}</p>
                <Calendar
                    v-model="order.activate_on"
                    showButtonBar
                    required
                    placeholder="(Optional)"
                    class="w-full"
                />       
                <p v-if="errors.activate_on" class="text-sm text-danger">{{ errors.activate_on[0] }}</p>               
            </div>

            <div class="flex justify-between mt-12">
                <span @click="isAddingProduct = true" class="block w-1/3 py-3 text-center text-white rounded-lg cursor-pointer bg-primary">{{ $t('titles.add_product') }}</span>
                <button class="px-6 py-3 text-white rounded outline-none h-fit bg-secondary ring-0">{{ $t('actions.create') }}</button>
            </div>

            <!-- add products -->
            <Dialog v-model:visible="isAddingProduct" modal header="Add products to order" :style="{ width: '70rem' }">
                <ProductSelector :initialTypes="selectedTypes" @done="addSelectedTypes" />
            </Dialog>


            <ul class="grid grid-cols-3 gap-3 py-4 my-6">
                <li
                    v-for="item in selectedTypes"
                    :key="item.product_type_id"
                    class="relative p-4 bg-white border border-gray-300 rounded shadow"
                >
                    <span @click="unselectType(item.product_type_id , item.quantity)" :class="`absolute bg-white top-[-10px] right-[-10px] cursor-pointer  text-danger`" class="material-icons-outlined">cancel</span>
                    <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.name') }}</span>-<span class="block w-1/2 text-end">{{ item.productName }}</span></p>
                    <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.product_type') }}</span>-<span class="block w-1/2 text-end">{{ item.typeName }}</span></p>
                    <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.quantity') }}</span>-<span class="block w-1/2 text-end">{{ item.quantity }}</span></p>
                    <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.amount') }}</span>-<span class="block w-1/2 text-end">{{ item.amount }}</span></p>
                    <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.iccid') }}</span>-<span class="block w-1/2 text-end">{{ item.iccid }}</span></p>
                    <p class="flex items-center justify-between mb-10 line-clamp-2"><span class="block w-1/2">{{ $t('labels.remark') }}</span>-<span class="block w-1/2 text-end">{{ item.remark }}</span></p>
                    <span @click="editProductTypeFields(item.product_type_id , item.quantity , item.iccid , item.remark)" class="absolute px-3 py-1 border rounded cursor-pointer text-secondary right-3 bottom-3 border-secondary">
                        {{ $t('actions.edit') }}
                    </span>
                </li>    
            </ul>

            <Dialog v-model:visible="isEditingProductType" modal header="Edit Product" :style="{ width: '50rem' }">
                <form @submit.prevent="updateProductType" class="grid grid-cols-2 gap-4">
                    <div>
                        <label class="block text-label" for="quantity">{{ $t('labels.quantity') }}</label>
                        <InputNumber
                            v-model="editProductType.quantity"
                            class="w-full"
                        />
                        <p v-if="errors.quantity" class="mt-1 text-sm text-danger">{{ errors.quantity }}</p>
                    </div>
                    <div>
                        <label class="block text-label" for="quantity">{{ $t('labels.amount') }}</label>
                        <InputNumber
                            v-model="editProductType.amount"
                            class="w-full"
                        />
                        <p v-if="errors.quantity" class="mt-1 text-sm text-danger">{{ errors.quantity }}</p>
                    </div>
                    <div>
                        <label class="block" for="quantity">{{ $t('labels.iccid') }}</label>
                        <InputText
                            v-model="editProductType.iccid"
                            class="w-full"
                        />
                    </div>
                    <div>
                        <label class="block" for="quantity">{{ $t('labels.remark') }}</label>
                        <InputText
                            v-model="editProductType.remark"
                            class="w-full"
                        />
                    </div>
                    <div class="flex justify-end w-full col-span-2 gap-2 mt-2">
                        <span @click="editProductType = {};isEditingProductType = false" class="px-8 py-2 rounded cursor-pointer text-primary">{{ $t('actions.cancel') }}</span>
                        <button class="px-8 py-2 text-white rounded bg-primary">{{ $t('actions.update') }}</button>
                    </div>
                </form>
            </Dialog>


        </form>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";

import ApiService from "@/services/ApiService";
import  {
    getStates,
    getAcceptTimes,
} from '@/services/commonApis'

import ProductSelector from '@/components/products/ProductSelector.vue'
import { toast } from 'vue3-toastify';
import Cookies from "js-cookie";

//prime vue components
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';

const router = useRouter();

const user = JSON.parse(Cookies.get('user'))

const states = ref([]);
const acceptTimes = ref([]);

const order = ref({
    user_id : user.id,
    name : '',
    email : '',
    phone_no : '',
    fb_link : '',
    apartment_name : '',
    postal_code : '',
    state_id : null,
    address : '',
    source_id : null,
    delivery_accept_date : '',
    delivery_date : '',
    delivery_accept_time_id : null,
    delivery_type_id : null,
    delivery_agent_id : null,
    payment_method_id : null,
    payment_status : '',
    cod_amount : '',
    tracking_code : '',
    activate_on : '',
    types : []
})
const errors = ref({});

const isAddingProduct = ref(false);
const selectedTypes = ref([]);
const isEditingProductType = ref(false);
const editProductType = ref({});

const checkPostalCode = (e) => {
    ApiService.get(`https://apis.postcode-jp.com/api/v5/postcodes/${e.target.value}?apikey=fFlNHwUdI50gi3MI6nfA20S577K4E7punaX39FR`).then((res) => {
        let pref = res[0].pref;
        if (pref) {
            order.value.address = `${res[0].city} ${res[0].town}`
            let state = states.value.find(s => s.name == pref);
            if (state != undefined) {
                order.value.state_id = state.id;
            } 
        }
    }).catch(() => {})
}

const addSelectedTypes = (types) => {
    selectedTypes.value = types.value;
    isAddingProduct.value = false;
}
const unselectType = (id , quantity) => {
    selectedTypes.value = selectedTypes.value.filter((t) => {
        if (t.product_type_id != id) {
            return true;
        } else {
            return t.quantity != quantity;
        }
    })
}
const editProductTypeFields = (id , quantity , iccid , remark) => {
    let index = 0;
    const item = selectedTypes.value.find((t , i) => {
        if (id == t.product_type_id && quantity == t.quantity && iccid == t.iccid && remark == t.remark) {
            index = i;
            return true;
        }
    });
    if (item) {
        editProductType.value.amount = item.amount;
        editProductType.value.iccid = item.iccid;
        editProductType.value.productName = item.productName;
        editProductType.value.product_type_id = item.product_type_id;
        editProductType.value.quantity = item.quantity;
        editProductType.value.remark = item.remark;
        editProductType.value.typeName = item.typeName;
        editProductType.value.index = index;
        isEditingProductType.value = true;
    }
}
const updateProductType = () => {
    let index = editProductType.value.index;
    const item = selectedTypes.value.find((t , i) => {
        if (editProductType.value.product_type_id == t.product_type_id && index == i) {
            return true;
        }
    });
    const compareData = () => {
        let itemKeys = Object.keys(item);
        for (let key of itemKeys) {
            if (item[key] !== editProductType.value[key]) {
                return true;
            }
        }
        return false;
    }
    const isDifferent = compareData();
    if (isDifferent) {
        selectedTypes.value[index].amount = editProductType.value.amount;
        selectedTypes.value[index].iccid = editProductType.value.iccid;
        selectedTypes.value[index].quantity = editProductType.value.quantity;
        selectedTypes.value[index].remark = editProductType.value.remark;
        isEditingProductType.value = false;
    } else {
        editProductType.value = {};
        isEditingProductType.value = false
    }
}


const convertDate = (dateString) => {
    const myanmarDate = new Date(dateString);
    const year = myanmarDate.getFullYear();
    const month = myanmarDate.getMonth() + 1;
    const day = myanmarDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    return formattedDate;
}

const createOrder = () => {
    order.value.cod_amount = order.value.payment_status == 'cod' ? order.value.cod_amount : null;
    const id = toast.loading('Creating, please wait . . .');
    if (order.value.delivery_accept_date) {
        order.value.delivery_accept_date = convertDate(order.value.delivery_accept_date);
    }
    if (order.value.delivery_date) {
        order.value.delivery_date = convertDate(order.value.delivery_date);
    }
    if (order.value.activate_on) {
        order.value.activate_on = convertDate(order.value.activate_on);
    }
    selectedTypes.value.forEach((t) => {
        let obj = {
            product_type_id : t.product_type_id,
            amount : t.amount,
            quantity : t.quantity,
            iccid : t.iccid,
            remark : t.remark
        }
        order.value.types.push(obj)
    })
    ApiService.post('affiliate/orders' , order.value).then(() => {
        toast.update(id, {
            render: "Created order successfully.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        router.push({name : 'AffiliateOrders' , query : {page : 1 , perPage : 10}});
        // window.location.assign('/create?page=1&per_page=10')
    }).catch((res) => {
        toast.update(id, {
            render: "Error creating order.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}

const getData = async () => {
    getStates(1 , 'all').then((res) => {
        states.value = res?.data;
    })
    getAcceptTimes(1 , 'all').then((res) => {
        acceptTimes.value = res?.data;
    })
}

onMounted(() => {
    getData().catch(() => {
        toast.error("Error fetching related data.")
    })
})
</script>

<style scoped>

</style>