import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie'

import LoginView from '@/views/auth/LoginView.vue'
import RegisterView from '@/views/auth/RegisterView.vue'

import DashboardLayout from '@/layouts/DashboardLayout.vue'

import OrdersView from '@/views/orders/OrdersView.vue'
import CreateOrder from '@/views/orders/CreateOrder.vue'
import OrderDetails from '@/views/orders/OrderDetails.vue'
import EditOrder from '@/views/orders/EditOrder.vue'

import StatesView from '@/views/states/StatesView.vue'
import PaymentMethodsView from '@/views/payment_methods/PaymentMethodsView.vue'
import DeliveryAgentsView from '@/views/delivery_agents/DeliveryAgentsView.vue'
import AcceptTimesView from '@/views/accept_times/AcceptTimesView.vue'
import ProductsView from '@/views/products/ProductsView.vue'
import ProductTypes from '@/views/product_types/ProductTypes.vue'
import ProductAnalysis from '@/views/products/ProductAnalysis.vue'
import ProductRules from '@/views/products/ProductRules.vue'
import DeliveryTypesView from '@/views/delivery_types/DeliveryTypesView.vue'
import SourcesView from '@/views/sources/SourcesView.vue'

//report module
import CategoriesView from '@/views/categories/CategoriesView.vue'
import SubCategories from '@/views/sub_categories/SubCategories.vue'
import StatusesView from '@/views/statuses/StatusesView.vue'
import ReportsView from '@/views/reports/ReportsView.vue'
import CreateReport from '@/views/reports/CreateReport.vue'
import EditReport from '@/views/reports/EditReport.vue'

import NotFound from '@/views/NotFound.vue'
import AffiliateLayout from '@/layouts/AffiliateLayout.vue'
import AffiliateOrders from '@/views/affiliates/orders/AffiliateOrders.vue'
import AffiliateCreate from '@/views/affiliates/orders/AffiliateCreate.vue'
import AffiliateOrderDetails from '@/views/affiliates/orders/OrderDetails.vue'

import InstocksView from '@/views/instocks/InstocksView.vue'
import InstockProducts from '@/views/instock_products/InstockProducts.vue'

const routes = [
  {
    path : '/',
    redirect : '/dashboard/orders?page=1',
    meta : {
      middleware : 'auth'
    }
  },
  {
    path : '/login',
    name : 'LoginView',
    component : LoginView
  },
  {
    path : '/register',
    name : 'RegisterView',
    component : RegisterView
  },
  {
    path : '/dashboard',
    name : 'DashboardLayout',
    component : DashboardLayout,
    meta : {
      middleware : 'auth'
    },
    children : [
      {
        path : 'orders',
        name : 'OrdersView',
        component : OrdersView
      },
      {
        path : 'orders/create',
        name : 'CreateOrder',
        component : CreateOrder
      },
      {
        path : 'orders/details/:id',
        name : 'OrderDetails',
        component : OrderDetails
      },
      {
        path : 'orders/edit/:id',
        name : 'EditOrder',
        component : EditOrder
      },    
      {
        path : 'states',
        name : 'StatesView',
        component : StatesView
      },
      {
        path : 'payment-methods',
        name : 'PaymentMethodsView',
        component : PaymentMethodsView
      },
      {
        path : 'delivery-agents',
        name : 'DeliveryAgentsView',
        component : DeliveryAgentsView
      },
      {
        path : 'delivery-accept-times',
        name : 'AcceptTimesView',
        component : AcceptTimesView
      },
      {
        path : 'products',
        name : 'ProductsView',
        component : ProductsView
      },
      {
        path : 'product-types/:id',
        name : 'ProductTypes',
        component : ProductTypes
      },
      {
        path : 'products/:id/rules-and-regulations',
        name : 'ProductRules',
        component : ProductRules
      },
      {
        path : 'products/:id/analytics',
        name : 'ProductAnalytics',
        component : ProductAnalysis
      },
      {
        path : 'delivery-types',
        name : 'DeliveryTypesView',
        component : DeliveryTypesView
      },
      {
        path : 'sources',
        name : 'SourcesView',
        component : SourcesView
      },
      {
        path : 'categories',
        name : 'CategoriesView',
        component : CategoriesView
      },
      {
        path : 'sub-categories',
        name : 'SubCategories',
        component : SubCategories
      },
      {
        path : 'statuses',
        name : 'StatusesView',
        component : StatusesView
      },
      {
        path : 'reports',
        name : 'ReportsView',
        component : ReportsView
      },
      {
        path : 'reports/create',
        name : 'CreateReport',
        component : CreateReport
      },
      {
        path : 'reports/:id',
        name : 'EditReport',
        component : EditReport
      },
      {
        path : 'instocks',
        name : 'InstocksView',
        component : InstocksView
      },
      {
        path : 'instock-products',
        name : 'InstockProducts',
        component : InstockProducts
      },
    ]
  },
  {
    path : '/orders',
    name : 'AffiliateLayout',
    component : AffiliateLayout,
    meta : {
      middleware : 'affiliate',
    },
    children : [
      {
        path : '',
        name : 'AffiliateOrders',
        component : AffiliateOrders
      },
      {
        path : '/create',
        name : 'AffiliateCreate',
        component : AffiliateCreate
      },
      {
        path : '/:id',
        name : 'AffiliateOrderDetails',
        component : AffiliateOrderDetails
      }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to , _ , next) => {
  if (to.meta.middleware == 'auth') {
    let token = Cookies.get('token');
    let roles = Cookies.get('roles') ? JSON.parse(Cookies.get('roles')) : [];
    if (token) {
      if (!roles.includes('Affiliate')) {
        next();
      } else {
        next('/orders?page=1&per_page=10')
      }
    } else {
      next('/login')
    }
  } else if (to.meta.middleware == 'affiliate') {
    let token = Cookies.get('token');
    let roles = Cookies.get('roles') ? JSON.parse(Cookies.get('roles')) : [];
    if (token) {
      if (roles.includes('Affiliate')) {
        next();
      } else {
        next('/login')
      }
    } else {
      next('/login')
    }
  } else {
    next();
  }
})

// router.beforeEach((to , _ , next) => {
//    else {
//     next();
//   }
// })

export default router
