<template>
    <div>
        <ul class="grid grid-cols-3 gap-3 py-4 mb-2">
            <li
                v-for="item in selectedTypes"
                :key="item.product_type_id"
                class="relative p-2 bg-white border border-gray-300 rounded shadow"
            >
            <span @click="unselectType(item.product_type_id , item.quantity)" :class="`absolute bg-white top-[-10px] right-[-10px] cursor-pointer text-danger`" class="material-icons-outlined">cancel</span>
            <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.name') }}</span>-<span class="block w-1/2 text-end">{{ item.productName }}</span></p>
            <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.product_type') }}</span>-<span class="block w-1/2 text-end">{{ item.typeName }}</span></p>
            <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.quantity') }}</span>-<span class="block w-1/2 text-end">{{ item.quantity }}</span></p>
            <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.amount') }}</span>-<span class="block w-1/2 text-end">{{ item.amount }}</span></p>
            <p class="flex items-center justify-between"><span class="block w-1/2">{{ $t('labels.iccid') }}</span>-<span class="block w-1/2 text-end">{{ item.iccid }}</span></p>
            <p class="flex items-center justify-between line-clamp-2"><span class="block w-1/2">{{ $t('labels.remark') }}</span>-<span class="block w-1/2 text-end">{{ item.remark }}</span></p>
            </li>    
        </ul>
        <Accordion @update:active-index="closeTab" v-if="products.length" >
            <AccordionTab v-for="product in products" :key="product.name">
                <template #header>
                    <div class="flex items-center gap-4">
                        <img class="w-[80px] aspect-video object-cover rounded" :src="product.image?.url">
                        <p>{{ product.name }}</p>
                    </div>
                </template>
                <ul class="grid grid-cols-3 gap-2 list-decimal list-inside">
                    <p v-if="product.types.length == 0" class="col-span-4 py-3 text-center">{{ $t('labels.no_product_message') }}</p>
                    <li
                        v-for="t in product.types"
                        :key="t.id"
                        class="flex flex-wrap items-center gap-3 p-2 rounded-lg cursor-pointer h-fit"
                        style="border: 1px solid gray;"
                        :class="currentlySelected.product_type_id == t.id ? 'bg-success text-white' : 'bg-transparent text-gray-700 hover:text-success'"
                    >
                        <img class="w-[60px] aspect-video rounded" :src="t.image?.url">
                        <p
                            @click="selectType(product , t)"
                            class="flex-grow"
                        >
                        {{ t.name }}</p>

                    </li>
                    <form v-if="currentlySelected.product_type_id" @submit.prevent="addToSelection" class="grid items-center grid-cols-2 col-span-3 gap-2 p-3 my-4 overflow-hidden transition-all border rounded-lg border-success justify-evenly">
                        <h1 class="col-span-2 font-semibold underline text-success">{{ currentlySelected.typeName }}</h1>
                        <div>
                            <label class="block text-label" for="quantity">{{ $t('labels.quantity') }}</label>
                            <InputNumber
                                v-model="currentlySelected.quantity"
                                class="w-full"
                            />
                            <p v-if="errors.quantity" class="mt-1 text-sm text-danger">{{ errors.quantity }}</p>
                        </div>
                        <div>
                            <label class="block text-label" for="quantity">{{ $t('labels.amount') }}</label>
                            <InputNumber
                                v-model="currentlySelected.amount"
                                class="w-full"
                            />
                            <p v-if="errors.quantity" class="mt-1 text-sm text-danger">{{ errors.quantity }}</p>
                        </div>
                        <div>
                            <label class="block" for="quantity">{{ $t('labels.iccid') }}</label>
                            <InputText
                                v-model="currentlySelected.iccid"
                                class="w-full"
                            />
                        </div>
                        <div>
                            <label class="block" for="quantity">{{ $t('labels.remark') }}</label>
                            <InputText
                                v-model="currentlySelected.remark"
                                class="w-full"
                            />
                        </div>
                        <div class="flex justify-end w-full col-span-2 gap-2 mt-2">
                            <span @click="currentlySelected = {}" class="px-8 py-2 rounded cursor-pointer text-primary">{{ $t('actions.cancel') }}</span>
                            <button class="px-8 py-2 text-white rounded bg-primary">{{ $t('actions.add') }}</button>
                        </div>
                    </form>
                </ul>
            </AccordionTab>
        </Accordion>
        <div v-else-if="!products.length && !loading" class="py-12 text-center">
            {{ $t('labels.no_products') }}
        </div>
        <div v-if="meta.current_page < meta.last_page" class="flex justify-center py-8">
            <button @click="loadMore" class="flex items-center text-xl text-success">
                <span>{{ $t('labels.load_more') }}</span>
                <span class="material-icons-outlined">expand_more</span>
            </button>
        </div>
        <div v-if="loading">
            <LoadingIndi />
        </div>
        <div class="mt-6">
            <button @click="doneSelecting" class="w-full py-4 text-white rounded-lg bg-success">{{ $t('actions.done') }}</button>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref , defineEmits , defineProps } from 'vue';
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';

import LoadingIndi from '@/components/LoadingIndi.vue';

// primevue imports
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';

const emit = defineEmits(['done']);
const props = defineProps({
    initialTypes : Array
})

const loading = ref(true);
const products = ref([]);
const meta = ref([]);
const selectedTypes = ref([]);
const currentlySelected = ref({});
const errors = ref({});

const selectType = (product , t) => {
    let obj = {
        productName : product.name,
        typeName : t.name,
        product_type_id : t.id,
        quantity : null,
        amount : null,
        iccid : '',
        remark : ''
    }
    currentlySelected.value = obj;
}

const closeTab = () => {
    currentlySelected.value = {}
}

const loadMore = () => {
    meta.value.current_page += 1;
    getProducts(meta.value.current_page);
}

const addToSelection = () => {
    if (currentlySelected.value.amount && currentlySelected.value.quantity) {
        selectedTypes.value.push(currentlySelected.value)
        currentlySelected.value = {};
    } else {
        errors.value = {
            amount : "The amount is required.",
            quantity : "The quantity is required."
        }
        setTimeout(() => {
            errors.value = {};
        } , 5000)
    }
}

const unselectType = (id , quantity) => {
    selectedTypes.value = selectedTypes.value.filter((t) => {
        if (t.product_type_id != id) {
            return true;
        } else {
            return t.quantity != quantity;
        }
    })
}

const doneSelecting = () => {
    emit('done' , selectedTypes)
}

const getProducts = (page) => {
    loading.value = true;
    ApiService.get(`products?page=${page}&limit=all`).then((res) => {
        products.value.push(...res.data);
        meta.value = res.meta;
        loading.value = false;
    }).catch(() => {
        toast.error("Error fetching data.");
        loading.value = false;
    })
}

onMounted(() => {
    if (props.initialTypes && props.initialTypes.length) {
        selectedTypes.value.push(...props.initialTypes);
    }
    getProducts(1);
})

</script>

<style scoped>

</style>