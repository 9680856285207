<template>
    <div class="flex flex-wrap justify-center p-2 pb-12 text-gray-700">
        <div class="flex items-center w-full gap-3 mx-auto lg:w-11/12">
            <router-link :to="{name : 'ReportsView' , query : {page : 1 , perPage : 10}}" class="mt-1">
                <span class="material-icons-round">west</span> 
            </router-link>
            <h1 class="text-2xl">Create Report</h1>
        </div>
        <form @submit.prevent="createReport" class="w-full lg:w-11/12">
            <div class="grid gap-4 mt-2 md:grid-cols-2">
                <div>
                    <label class="block mb-1 text-label" for="title">Title</label>
                    <InputText
                        v-model="report.title"
                        name="title"
                        type="text"
                        placeholder="report title"
                        class="w-full"
                     />      
                     <p v-if="errors.title" class="text-sm text-danger">{{ errors.title[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1" for="sub_title">Sub Title</label>
                    <InputText
                        v-model="report.sub_title"
                        name="sub_title"
                        type="text"
                        placeholder="sub title"
                        class="w-full"
                     />      
                     <p v-if="errors.sub_title" class="text-sm text-danger">{{ errors.sub_title[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1 text-label" for="category_id">Category</label>
                    <Dropdown
                        v-model="report.category_id"
                        :options="categories"
                        option-label="name"
                        option-value="id"
                        optionLabel="name"
                        placeholder="report category"
                        @change="fetchSubCategory"
                        filter
                        class="w-full" />      
                        <p v-if="errors.category_id" class="text-sm text-danger">{{ errors.category_id[0] }}</p>                 
                </div>
                <div>
                    <label class="block mb-1 text-label" for="sub_category_id">Sub Category</label>
                    <Dropdown
                        v-model="report.sub_category_id"
                        :options="subCategories"
                        option-label="name"
                        option-value="id"
                        optionLabel="name"
                        placeholder="sub category"
                        filter
                        class="w-full" />      
                        <p v-if="errors.sub_category_id" class="text-sm text-danger">{{ errors.sub_category_id[0] }}</p>                 
                </div>
                <div>
                    <label class="block mb-1 text-label" for="status_id">Status</label>
                    <Dropdown
                        v-model="report.status_id"
                        :options="statuses"
                        option-label="name"
                        option-value="id"
                        optionLabel="name"
                        placeholder="report status"
                        filter
                        class="w-full" />      
                        <p v-if="errors.status_id" class="text-sm text-danger">{{ errors.status_id[0] }}</p>                 
                </div>
                <div>
                    <label class="block mb-1 text-label" for="report_month">Report Month</label>
                    <Calendar
                        v-model="reportMonth"
                        placeholder="report month"
                        view="month"
                        dateFormat="mm/yy"
                        showButtonBar
                        class="w-full"
                    />       
                    <p v-if="errors.report_month" class="text-sm text-danger">{{ errors.report_month[0] }}</p>               
                </div>
                <div>
                    <label class="block mb-1" for="phone_no">{{ $t('labels.phone_no') }}</label>
                    <InputText
                        v-model="report.phone_no"
                        name="phone_no"
                        type="text"
                        placeholder="12345"
                        class="w-full"
                     />      
                     <p v-if="errors.phone_no" class="text-sm text-danger">{{ errors.phone_no[0] }}</p>          
                </div>
                <div>
                    <label class="block mb-1" for="iccid">{{ $t('labels.iccid') }}</label>
                    <InputText
                        v-model="report.iccid"
                        name="iccid"
                        type="text"
                        placeholder="iccid"
                        class="w-full"
                     />      
                     <p v-if="errors.iccid" class="text-sm text-danger">{{ errors.iccid[0] }}</p>          
                </div>
                <div class="col-span-2 mb-20">
                    <label for="body" class="block mb-1 text-label ">Body</label>
                    <quill-editor
                        v-model:value="report.body"
                    />
                    <p v-if="errors.body" class="text-sm text-danger">{{ errors.body[0] }}</p>          
                </div>
            </div>
            <div class="flex justify-end mt-12">
                <button type="submit" class="px-6 py-3 text-white rounded outline-none h-fit bg-secondary ring-0">{{ $t('actions.create') }}</button>
            </div>
        </form>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getCategories , getSubCategories , getStatuses } from '@/services/commonApis.js'
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import { quillEditor } from 'vue3-quill'
import ApiService from "@/services/ApiService";
import { toast } from 'vue3-toastify';
import { useRouter } from "vue-router";

const router = useRouter();

const report = ref({
    title : '',
    sub_title : '',
    report_month : '',
    category_id : '',
    sub_category_id : '',
    status_id : '',
    body : '',
    phone_no : '',
    iccid : ''
})
const reportMonth = ref('');
const errors = ref({});
const categories = ref([]);
const subCategories = ref([]);
const statuses = ref([]);

const convertDate = (dateString) => {
    const myanmarDate = new Date(dateString);
    const year = myanmarDate.getFullYear();
    const month = myanmarDate.getMonth() + 1;
    const day = myanmarDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    return formattedDate;
}

const createReport = () => {
    const id = toast.loading('Creating, please wait . . .');
    report.value.report_month = convertDate(reportMonth.value);
    ApiService.post('reports' , report.value).then(() => {
        toast.update(id, {
            render: "Created report successfully.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        router.push({name : 'ReportsView' , query : {page : 1 , perPage : 10}});
    }).catch((res) => {
        toast.update(id, {
            render: "Error creating report.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
        errors.value = res?.response?.data && res?.response?.data?.errors ? res?.response?.data?.errors : {};
    })
}

const fetchSubCategory = ({value}) => {
    ApiService.get(`sub-categories?category=${value}`).then((res) => {
        subCategories.value = res.data;
    })
}

onMounted(() => {
    getCategories(1 , "all").then((res) => {
        categories.value = res.data;
    })
    getSubCategories(1 , "all").then((res) => {
        subCategories.value = res.data;
    })
    getStatuses(1 , "all").then((res) => {
        statuses.value = res.data;
    })
})

</script>

<style scoped>

</style>