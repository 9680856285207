<template>
    <div>
        <!-- filters -->
        <h1 class="text-[1.35rem] text-primary font-semibold mt-8 px-3">{{ $t('titles.filters') }}</h1>
        <div class="grid grid-cols-2 gap-4 px-3 mt-2 mb-6 md:grid-cols-4">
            <div>
                <label class="block mb-1" for="id">{{ $t('labels.id') }}</label>
                <InputText
                    v-model="filters.id"
                    @change="filterList(currentPage)"
                    name="id"
                    placeholder="123"
                    class="w-full"
                    :class="filters.id ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="name">{{ $t('labels.name') }}</label>
                <InputText
                    v-model="filters.name"
                    @change="filterList(currentPage)"
                    name="name"
                    placeholder="John Doe"
                    class="w-full"
                    :class="filters.name ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="email">{{ $t('labels.email') }}</label>
                <InputText
                    v-model="filters.email"
                    @change="filterList(currentPage)"
                    name="email"
                    placeholder="john@gmail.com"
                    class="w-full"
                    :class="filters.email ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="ordered_date">{{ $t('labels.ordered_date') }}</label>
                <Calendar
                    @update:modelValue="convertDate($event , 'created_at')"
                    v-model="dates.created_at"
                    @clear-click="clearDate('created_at')"
                    showButtonBar
                    placeholder="01-01-2024"
                    class="w-full"
                    :class="filters.created_at ? 'border-success' : ''"
                />     
            </div>
            <div>
                <label class="block mb-1" for="payment_method">{{ $t('labels.payment_method') }}</label>
                <MultiSelect
                    v-model="filters.payment_method_id"
                    :options="paymentMethods"
                    @change="filterList(currentPage)"
                    display="chip"
                    option-label="name"
                    option-value="id"
                    placeholder="payment method"
                    class="w-full"
                    :class="filters.payment_method_id?.length ? 'border-success' : ''"
                />        
            </div>
            <div>
                <label class="block mb-1" for="payment_status">{{ $t('labels.payment_status') }}</label> 
                <MultiSelect
                    v-model="filters.payment_status"
                    :options="['unpaid' , 'paid' , 'cod' , 'error change']"
                    @change="filterList(currentPage)"
                    display="chip"
                    placeholder="payment status"
                    class="w-full"
                    :class="filters.payment_status?.length ? 'border-success' : ''"
                />         
            </div>
            <div>
                <label class="block mb-1" for="delivery_accept_date">{{ $t('labels.accept_date') }}</label>
                <Calendar
                    @update:modelValue="convertDate($event , 'delivery_accept_date')"
                    @clear-click="clearDate('delivery_accept_date')"
                    v-model="dates.delivery_accept_date"
                    showButtonBar
                    placeholder="01-01-2024"
                    class="w-full"
                    :class="filters.delivery_accept_date ? 'border-success' : ''"
                />    
            </div>
            <div>
                <label class="block mb-1" for="deli_status">{{ $t('labels.deli_status') }}</label>
                <MultiSelect
                    v-model="filters.delivery_status"
                    :options="[ 'ordered' , 'shipped' , 'canceled' , 'delivered' , 'return' , 'investigation' , 'absent' , 'address unknown' , 'pre order']"
                    @change="filterList(currentPage)"
                    display="chip"
                    placeholder="delivery status"
                    class="w-full"
                    :class="filters.delivery_status?.length ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="tracking_code">{{ $t('labels.tracking_code') }}</label>
                <InputText
                    name="tracking_code"
                    v-model="filters.tracking_code"
                    @change="filterList(currentPage)"
                    placeholder="12345"
                    class="w-full"
                    :class="filters.tracking_code ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="tracking_code">{{ $t('labels.activate_on') }}</label>
                <Calendar
                    v-model="dates.activate"
                    @update:modelValue="convertDate($event , 'activate')"
                    @clear-click="clearDate('activate')"
                    view="month"
                    dateFormat="mm/yy" 
                    class="w-full"
                    :class="filters.activate ? 'border-success' : ''"
                    placeholder="activate on"
                    showButtonBar
                />
            </div>
            <div>
                <label class="block mb-1" for="iccid">{{ $t('labels.iccid') }}</label>
                <InputText
                    name="iccid"
                    v-model="filters.iccid"
                    @change="filterList(currentPage)"
                    placeholder="iccid"
                    class="w-full"
                    :class="filters.iccid ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="remark">{{ $t('labels.remark') }}</label>
                <InputText
                    name="remark"
                    v-model="filters.remark"
                    @change="filterList(currentPage)"
                    placeholder="remark"
                    class="w-full"
                    :class="filters.remark ? 'border-success' : ''"
                />
            </div>
            <div>
                <label class="block mb-1" for="return_status">{{ $t('labels.return_status') }}</label>
                <Dropdown
                    showClear
                    placeholder="return status"
                    class="w-full"
                    :class="filters.return_status ? 'border-success' : ''"
                    v-model="filters.return_status"
                    @change="filterList(currentPage)"
                    :options="['no return' , 'return start' , 'return done']"
                />
            </div>
        </div>
        <ConfirmDialog></ConfirmDialog>
        <LoadingIndi v-if="loading" />
        <DataTable
            v-else
            v-model:selection="selectedOrders"
            :row-hover="true"
            @row-select="rowSelect"
            @row-select-all="rowSelectAll"
            @row-unselect="rowUnselect"
            @row-unselect-all="rowUnselectAll"
            :value="orders"
            tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-between">
                    <h1 class="text-2xl font-normal">{{$t('titles.orders')}}</h1>
                    <router-link :to="{name : 'CreateOrder'}">
                        <Button :label="$t('actions.create')" class="px-6 py-3 text-white border-0 bg-secondary" size="small" />
                    </router-link>
                </div>
            </template>
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column field="id" :header="$t('labels.id')"></Column>
            <Column field="exported" :header="$t('labels.is_exported')">
                <template #body="slotProps">
                    <span v-if="slotProps.data.exported" class="bg-success text-white font-semibold w-[60px] rounded-full capitalize py-1.5 flex justify-center">Yes</span>
                    <span v-else class="bg-primary text-white font-semibold w-[60px] rounded-full capitalize py-1.5 flex justify-center">No</span>
                </template>
            </Column>
            <Column field="name" :header="$t('labels.name')"></Column>
            <Column field="user.name"  :header="$t('labels.created_by')"></Column>
            <Column field="created_at"  :header="$t('labels.ordered_date')">
                <template #body="slotProps">
                    <span>{{ slotProps.data?.created_at.split(",")[0] }}</span>
                </template>
            </Column>
            <Column field="activate_on"  :header="$t('labels.activate_on')"></Column>
            <Column field="delivery_accept_date"  :header="$t('labels.accept_date')"></Column>
            <Column field="delivery_accept_time.name"  :header="$t('labels.accept_time')"></Column>
            <Column field="delivery_status"  :header="$t('labels.deli_status')">
                <template #body="slotProps">
                    <button
                        v-if="slotProps.data.delivery_status == 'ordered'"
                        :class="chipColor(slotProps.data.delivery_status)"
                        @click="changeStatus(slotProps.data.id)"
                        v-tooltip.bottom="'Click to change status'"
                        class="hover:shadow-lg">
                        Ordered
                    </button>
                    <span v-else :class="chipColor(slotProps.data.delivery_status)" class="">{{ slotProps.data.delivery_status }}</span>
                </template>
            </Column>
            <Column field="payment_method.name"  :header="$t('labels.payment_method')"></Column>
            <Column field="payment_status"  :header="$t('labels.payment_status')">
                <template #body="slotProps">
                    <span v-if="slotProps.data.payment_status == 'cod'" :class="chipColor(slotProps.data.payment_status)" class="">Cash on deli</span>
                    <span v-else :class="chipColor(slotProps.data.payment_status)" class="">{{ slotProps.data.payment_status }}</span>
                </template>
            </Column>
            <Column field="custom" :header="$t('labels.actions')">
                <template #body="slotProps">
                    <div class="relative flex items-center gap-3 text-white">
                        <router-link v-tooltip.bottom="'View Details'" class="flex items-center justify-center p-2 rounded-full bg-secondary hover:bg-secondary/90" :to="{name : 'EditOrder' , params : {id : slotProps.data.id}}">
                            <span style="font-size: 1.4rem;" class="material-icons-round">visibility</span>
                        </router-link>
                        <button v-tooltip.bottom="'Copy Order'" @click="copyOrder(slotProps.data.id)" class="flex items-center justify-center p-2 rounded-full bg-success hover:bg-success/90">
                            <span style="font-size: 1.4rem;" class="material-icons-outlined">content_copy</span>
                        </button>
                    </div>
                </template>
            </Column>
            <template #footer>
                <div class="flex w-full font-bold" :class="selectedOrders.length ? 'justify-between' : 'justify-end'">
                    <button v-if="selectedOrders.length" @click="exportOrders" class="px-6 py-3 text-white rounded-lg hover:bg-success/80 h-fit bg-success">
                        Export selected orders
                    </button>
                    <div class="flex items-center gap-4">
                        <Dropdown
                            v-model="perPage"
                            :options="[10 , 20 , 30 , 50]"
                            @update:model-value="changePerPage($event)"
                        />
                        <Paginator
                            v-if="meta.total"
                            @page="onPaginate($event)"
                            :rows="meta.perPage"
                            v-model:first="meta.from"
                            :current-page-report-template="meta.current_page"
                            :totalRecords="meta.total"
                            ></Paginator>
                            <!-- :rowsPerPageOptions="[10, 20, 30]" -->
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRouter , useRoute } from 'vue-router';
import ApiService from '@/services/ApiService';
import { getPaymentMethods } from '@/services/commonApis'
import { toast } from 'vue3-toastify';

//primevue component imports
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Paginator from 'primevue/paginator';
import MultiSelect from 'primevue/multiselect';
import LoadingIndi from '@/components/LoadingIndi.vue';
import Dropdown from 'primevue/dropdown';
import ConfirmDialog from 'primevue/confirmdialog';

import { useConfirm } from "primevue/useconfirm";
import Cookies from 'js-cookie'

const router = useRouter();
const route = useRoute();

const confirm = useConfirm();

const currentPage = ref(isNaN(+route.query.page) ? 1 : +route.query.page);
const perPage = ref(isNaN(+route.query.perPage) ? 10 : +route.query.perPage)
const meta = ref({});
const orders = ref([]);
const loading = ref(true);
const selectedOrders = ref([]);

const changeStatus = (id) => {
    confirm.require({
        message: `Are you sure you want to change delivery status of order #${id} to shipped?`,
        header: 'Confirmation',
        rejectLabel: 'Cancel',
        acceptLabel: 'Confirm',
        accept: () => {
            const toastId = toast.loading('Updating, please wait . . .');
            ApiService.put(`orders/update-delivery-status/${id}`).then(() => {
                orders.value = orders.value.map((order) => {
                    if (order.id == id) {
                        order.delivery_status = 'shipped';
                    }
                    return order;
                })
                toast.update(toastId, {
                    render: "Updated delivery status successfully.",
                    autoClose: true,
                    closeOnClick: true,
                    closeButton: true,
                    type: 'success',
                    isLoading: false,
                });
            }).catch((err) => {
                console.log(err);
                toast.update(toastId, {
                    render: err?.response?.data?.message ?? "Error Updating delivery status",
                    autoClose: true,
                    closeOnClick: true,
                    closeButton: true,
                    type: 'error',
                    isLoading: false,
                });
            })
        },
        reject: () => {
            return;
        }
    });
}

const rowSelect = () => {
}
const rowUnselect = () => {
}
const rowUnselectAll = () => {
}
const rowSelectAll = () => {
}

const exportOrders = () => {
    const tid = toast.loading('Exporting, please wait . . .');
    let orders = selectedOrders.value.map(order => order.id)
    ApiService.post('export-orders' , {orders : orders} , {responseType : 'blob'}).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let fileName = `orders_${year}_${month >= 10 ? month : '0'+month}_${day >= 10 ? day : '0'+day}`;
        link.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        toast.update(tid, {
            render: "Exported orders successfully.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        window.location.reload();
    }).catch(() => {
        toast.update(tid, {
            render: "Error exporting orders.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

const toArray = (value) => {
      if (value === undefined || value === null) {
        return [];
      } else if (Array.isArray(value)) {
        return value;
      } else {
        return [value];
      }
};

const filters = ref({
    id : route.query.id,
   perPage : 10,
    name : route.query.name,
    email : route.query.email,
    created_at : route.query.created_at,
    payment_method_id : toArray(route.query['payment_method_id']),
    payment_status : toArray(route.query['payment_status']),
    delivery_status : toArray(route.query['delivery_status']),
    delivery_accept_date : route.query.delivery_accept_date,
    tracking_code : route.query.tracking_code,
    activate : route.query.activate,
    iccid : route.query.iccid,
    remark : route.query.remark,
    return_status : route.query.return_status
})
const dates = ref({
    created_at : '',
    delivery_accept_date : '',
    activate : '',
});
const paymentMethods = ref([]);

watch(route , async () => {
    filters.value = {
        ...filters.value,
        perPage : perPage.value
    };
    let fl = JSON.stringify(filters.value);
    Cookies.set('order_filter' , fl);
})

const copyOrder = (id) => {
    const tid = toast.loading('Copying, please wait . . .');
    ApiService.get(`orders/${id}`).then((res) => {
        let ord = res.data;
        let formatProducts = () => {
            let text = '';
            ord.products?.forEach((t , index) => {
                text += `${index+1}. ${t.product.name} (${t.name}) × ${t.pivot.quantity} \n${t.pivot.remark ? t.pivot.remark : ''} \n\n`;
            })
            return text;
        }
        let text = `Name - ${ord.name}\nPhone no. - ${ord.phone_no ? ord.phone_no : ''}\nPostal code - ${ord.postal_code}\nAddress - ${ord.state?.name} ${ord.address} ${ord.apartment_name ? ord.apartment_name : ''}\nDelivery type - ${ord.delivery_type ? ord.delivery_type.name : ''}\nDelivery accept date - ${ord.delivery_accept_date ? ord.delivery_accept_date : ''}\nDelivery accept time - ${ord.delivery_accept_time ? ord.delivery_accept_time.name : ''}\n\n• Order Items List\n\n${formatProducts()}`
        navigator.clipboard.writeText(text)
        toast.update(tid, {
                render: "Copied order to clipboard.",
                autoClose: true,
                closeOnClick: true,
                closeButton: true,
                type: 'success',
                isLoading: false,
            });
    }).catch(() => {
        toast.update(tid, {
            render: "Error copying order.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

const chipColor = (status) => {
    if (status == 'delivered' || status == 'shipped' || status == 'paid') {
        return 'bg-success text-white font-semibold w-[120px] rounded-full capitalize py-2 flex justify-center';
    } else if(status == 'cod' || status == 'ordered' || status == 'pre order') {
        return 'bg-primary text-white font-semibold w-[120px] rounded-full capitalize py-2 flex justify-center';
    } else {
        return 'bg-yellow-500 text-white font-semibold w-[120px] text-center rounded-full py-2 flex justify-center capitalize'
    }
}

const fetchData = async (page) => {
    loading.value = true;
    return ApiService.get('orders' , {params : {...filters.value , page : page , perPage : perPage.value}}).then((res) => {
        loading.value = false;
        orders.value = res.data
        meta.value = res.meta
    }).catch(() => {
        loading.value = false;
    })
}

const clearDate = (field) => {
    filters.value[field] = '';
    dates.value[field] = '';
    filterList();
}

const convertDate = (dateString , field) => {
    if (dateString == null) {
        return;
    }
    const myanmarDate = new Date(dateString);
    const year = myanmarDate.getFullYear();
    const month = myanmarDate.getMonth() + 1;
    const day = myanmarDate.getDate();
    if (field == 'activate') {
        filters.value.activate = `${month < 10 ? '0' : ''}${month}-${year}`;
    } else {
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        filters.value[field] = formattedDate;
    }
    filterList();
}

const onPaginate = (e) => {
    currentPage.value = e.page + 1;
    router.push({name : 'OrdersView' , query : {page : currentPage.value , perPage : perPage.value , ...filterList.value}})
    fetchData(currentPage.value)
}

const changePerPage = (e) => {
    perPage.value = e;
    router.push({name : 'OrdersView' , query : {page : currentPage.value , perPage : perPage.value , ...filterList.value}})
    fetchData(currentPage.value)
}

const filterList = async () => {
    router.push({name : 'OrdersView' , query : {...filters.value , page : currentPage.value , perPage : perPage.value}})
    return fetchData(currentPage.value);
}

onMounted(() => {
    let list = Cookies.get('order_filter');
    if (list) {
        filters.value = JSON.parse(list);
        perPage.value = filters.value.perPage;
    }
    filterList().then(() => {
        getPaymentMethods(1 , "all").then((res) => {
            paymentMethods.value = res.data
        }).catch(() => {
            toast.error("Error fetching payment methods.");
        })
    });
    // fetchData(currentPage.value).then(() => {
    //     getPaymentMethods(1 , "all").then((res) => {
    //         paymentMethods.value = res.data
    //     }).catch(() => {
    //         toast.error("Error fetching payment methods.");
    //     })
    // });
})


</script>

<style scoped>

</style>