<template>
  <div>
    <div class="grid grid-cols-2 gap-4 px-3 mt-2 mb-6 md:grid-cols-4">
      <div>
        <label class="block mb-1" for="ordered_date">{{
          $t("labels.date")
        }}</label>
        <Calendar
          @update:modelValue="convertDate"
          v-model="date"
          view="month"
          placeholder="01-01-2024"
          class="w-full border-success"
        />
      </div>
    </div>
    <div class="flex justify-between px-4">
          <h1 class="text-2xl font-normal">{{ $t("titles.instocks") }}</h1>
          <Button
            @click="createForm = true"
            class="text-white border-0 bg-secondary"
            :label="$t('actions.create')"
            size="large"
          />
    </div>
    <LoadingIndi v-if="loading" />
    <div v-else class="grid gap-4 mt-6 sm:grid-cols-2 md:grid-cols-3">
      <div v-for="stock in instocks" :key="stock.date" class="p-3 instock-card max-h-[500px] overflow-y-scroll rounded-lg shadow">
        <h3 class="mb-2 text-lg font-semibold text-center underline text-primary">{{ stock.date }}</h3>
        <div v-for="record in stock.records" :key="record.product_id" class="flex items-baseline py-1">
          <p>{{ record.product?.name }}</p>
          <hr class="flex-grow mx-2">
          <p class="flex items-center gap-2 text-lg">
            <span>{{ record.instock }}</span>
            <span class="text-label">|</span>
            <button
              @click="showEditForm(record)"
              class="flex items-center justify-center p-2 text-xs text-white rounded-full bg-secondary"
            >
              <span style="font-size: 1rem" class="material-icons-round"
                >edit</span
              >
            </button>
          </p>
        </div>
      </div>
    </div>
    <!-- Create Form -->
    <Dialog
      v-model:visible="createForm"
      modal
      :header="`${$t('actions.create')} ${$t('titles.instocks')}`"
      :style="{ width: '60rem' }"
    >
      <CreateInstock @created="refreshData" />
    </Dialog>

    <!-- Edit Form -->
    <Dialog
      v-model:visible="editForm"
      modal
      :header="`${$t('actions.edit')} ${$t('titles.instocks')}`"
      :style="{ width: '50rem' }"
    >
      <EditInstock :instock="editInstock" @edited="refreshData" />
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import ApiService from "@/services/ApiService";

import CreateInstock from "@/components/instocks/CreateInstock.vue";
import EditInstock from "@/components/instocks/EditInstock.vue";

//primevue component imports
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import LoadingIndi from "@/components/LoadingIndi.vue";

const route = useRoute();
const router = useRouter();
const date = ref(route.query.date);
const loading = ref(true);
const instocks = ref([]);
const createForm = ref(false);
const editForm = ref(false);
const editInstock = ref(0);

const convertDate = (dateString) => {
  if (dateString == null) {
    return;
  }
  const myanmarDate = new Date(dateString);
  const year = myanmarDate.getFullYear();
  const month = myanmarDate.getMonth() + 1;
  const formattedDate = `${month < 10 ? "0" : ""}${month}-${year}`;
  date.value = formattedDate;
  router.push({ name: "InstocksView", query: { date: date.value } });
  fetchData(date.value);
};

const fetchData = (date) => {
  loading.value = true;
  ApiService.get("instocks", { params: { date: date } })
    .then((res) => {
      loading.value = false;
      instocks.value = res.data;
    })
    .catch(() => {
      loading.value = false;
    });
};

const refreshData = async () => {
  window.location.reload();
};

const showEditForm = (instock) => {
  editInstock.value = instock;
  editForm.value = true;
};

// const deleteInstock = (id) => {
//   const tid = toast.loading("Deleting, please wait . . .");
//   ApiService.delete(`instocks/${id}`)
//     .then(async () => {
//       toast.update(tid, {
//         render: "Deleted Instock.",
//         autoClose: true,
//         closeOnClick: true,
//         closeButton: true,
//         type: "success",
//         isLoading: false
//       });
//       window.location.reload();
//     })
//     .catch(() => {
//       toast.update(tid, {
//         render: "Error deleting instock.",
//         autoClose: true,
//         closeOnClick: true,
//         closeButton: true,
//         type: "error",
//         isLoading: false
//       });
//     });
// };

onMounted(async () => {
  fetchData(date.value);
});
</script>

<style scoped></style>
